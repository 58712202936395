<template lang="html">

  <section class="home">
    <div class="cabecera">
      <div class="container">
        <h1 style="padding-bottom: 2rem;">Garatu zure ibilbide profesionala Euskotrenen <br>Desarrolla tu carrera profesional en Euskotren </h1>
        <a class="btn-bases btn-result" target="_blank" download="" href="https://adeccoagencia.iformalia.es/Publico/Portal/Tablonanuncios.aspx?Tipo=w5ejsiePi%2fmaLy%2bhV2Lhp6bvZLWBFOP4UQMGhmgX8JjjdS2MnG7peg%3d%3d&itemid=XvdzlxezCgA%3d&app=Euskotren&carpeta=d3ih4lwT4JX8gHnqRU0JQw%3d%3d" style="margin-top: 30px;width: fit-content;text-transform: uppercase;display:none;">EMAITZAK / RESULTADOS</a>
        <a class="btn-bases btn-result" target="_blank" download="" href="https://adeccoagencia.iformalia.es/Publico/Portal/Tablonanuncios.aspx?Tipo=w5ejsiePi%2fmaLy%2bhV2Lhp6bvZLWBFOP4UQMGhmgX8JjjdS2MnG7peg%3d%3d&itemid=XvdzlxezCgA%3d&app=Euskotren&carpeta=LhIjZ%2fyOW%2bS8DdHY18sjgg%3d%3d" style="margin-top:30px;display: inline-block;width: fit-content;text-transform: uppercase;margin-right: 20px;display:none;" >OINARRIAK + ARAUDIA / BASES + NORMATIVA</a>
        <a class="btn-bases btn-result " target="_blank" download="" href="https://adeccoagencia.iformalia.es/Publico/Portal/Tablonanuncios.aspx?Tipo=w5ejsiePi%2fmaLy%2bhV2Lhp6bvZLWBFOP4UQMGhmgX8JjjdS2MnG7peg%3d%3d&itemid=XvdzlxezCgA%3d&app=Euskotren&carpeta=drmxznP5E%2fjLNgoRd%2be0NA%3d%3d" style="margin-top:30px;display: inline-block;width: fit-content;text-transform: uppercase;margin-right: 20px;" >EGUTEGIA / CALENDARIO</a>
        <br>
<a class="btn-bases btn-result " target="_blank" download="" href="https://adeccoagencia.iformalia.es/Publico/Portal/Tablonanuncios.aspx?Tipo=w5ejsiePi%2fmaLy%2bhV2Lhp6bvZLWBFOP4UQMGhmgX8JjjdS2MnG7peg%3d%3d&itemid=XvdzlxezCgA%3d&app=Euskotren&carpeta=aI1NK7oHDpM%3d" style="margin-top:30px;display: inline-block;width: fit-content;text-transform: uppercase;margin-right: 20px;">Programazioa / Temario</a>
<a class="btn-bases btn-result" target="_blank" download="" href="https://adeccoagencia.iformalia.es/Publico/Portal/Tablonanuncios.aspx?Tipo=w5ejsiePi%2fmaLy%2bhV2Lhp6bvZLWBFOP4UQMGhmgX8JjjdS2MnG7peg%3d%3d&itemid=XvdzlxezCgA%3d&app=Euskotren&carpeta=6x9%2bj9Rer7Mn8%2f%2bpgiob%2fRinYhGzIa%2fh" style="margin-top:30px;display: inline-block;width: fit-content;text-transform: uppercase;margin-right: 20px;display:none;">GALDERA SORTA / BATERIA DE PREGUNTAS</a>

<a class="btn-bases btn-result" target="_blank" download="" style="margin-top:30px;display:inline-block;width:fit-content;text-transform:uppercase;margin-right:20px;" href="https://adeccoagencia.iformalia.es/Publico/Portal/Tablonanuncios.aspx?Tipo=w5ejsiePi%2fmaLy%2bhV2Lhp6bvZLWBFOP4UQMGhmgX8JjjdS2MnG7peg%3d%3d&itemid=XvdzlxezCgA%3d&app=Euskotren&carpeta=rMqycGqEvHX2vjmP58hDGw%3d%3d">ARGITALPENAK / PUBLICACIONES</a>


<a class="btn-bases btn-result" target="_blank" download="" style="margin-top:30px;display:inline-block;width:fit-content;text-transform:uppercase;margin-right:20px;" href="https://adeccoagencia.iformalia.es/Publico/Portal/Tablonanuncios.aspx?Tipo=w5ejsiePi%2fmaLy%2bhV2Lhp6bvZLWBFOP4UQMGhmgX8JjjdS2MnG7peg%3d%3d&itemid=l7ewqTX%2b6HI%3d&app=Euskotren&Carpeta=mZGcnSNywgk%3d">Barne deialdiko iragarki taula/ Tablón de anuncios convocatoria interna</a>



<a class="btn-bases btn-result" target="_blank" download="" href="https://adeccoagencia.iformalia.es/Publico/Portal/Tablonanuncios.aspx?Tipo=w5ejsiePi%2fmaLy%2bhV2Lhp6bvZLWBFOP4UQMGhmgX8JjjdS2MnG7peg%3d%3d&itemid=Nm%2fiMhWkPZ8%3d&app=Euskotren&Carpeta=mZGcnSNywgk%3d" style="margin-top:60px;display:block;width:fit-content;text-transform:uppercase;margin-right:20px;background: linear-gradient(92.86deg,#cccbc8,#777574);">KANPO DEIALDIKO IRAGARKI TAULA/ TABLÓN DE ANUNCIOS CONVOCATORIA EXTERNA</a>



      </div>
    </div>

    <div class="container" id="bolsa">
      <div class="container cuerpo">
        <div class="titulo">
          <h2>ENPLEGU-POLTSA / BOLSA DE EMPLEO</h2>
          <hr>
        </div>
        <p style="align-items: center;display: none;flex-direction: column;justify-content: center;align-items: center;"></p>
        <div class="ofertas" >
          <div v-for="(offer, key) in $tm('offers')" :key="key">
            <h1>{{ offer.title }}</h1>
            <p>{{ offer.header }}</p>
            <router-link :to="{
              name: 'oferta',
              params: {
                slug: offer.slug,
              }
            }">
              <p class="saber">GEHIAGO JAKIN /SABER MÁS</p>
            </router-link>
          </div>
        </div>
      </div>

    </div>


    <div class="footer" id="euskotren">
      <h1>euskotren</h1>
      <h4>Garatu zure ibilbide profesionala gurekin / Desarrolla tu carrera profesional con nosotros </h4>
      <p>Euskotrenen hiri barruko eta hiriarteko mugikortasun iraunkorra bultzatzen dugu, pertsonak segurtasun-,
        erosotasun- eta puntualtasun-baldintza onenetan mugiaraziz, ekoeraginkortasun-irizpideekin eta gure inguruneko
        bizi-kalitatea hobetzen lagunduz. Era berean, salgaien trenbideko garraioa sustatzen dugu, zamaren sektorean
        errepidearen alternatiba gisa.
      </p>
      <p>En Euskotren impulsamos la movilidad sostenible urbana e interurbana, desplazando a las personas en las mejores
        condiciones de seguridad, confort y puntualidad, con criterios de ecoeficiencia y contribuyendo a mejorar la
        calidad de vida en nuestro entorno. Igualmente, fomentamos el transporte ferroviario de mercancías, como
        alternativa a la carretera en el sector de carga.
      </p>

    </div>
  </section>
</template>

<script lang="js">
  export default {
    name: 'home',
    props: ['slug'],
  }
</script>
